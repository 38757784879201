"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Frame One Software OpenAPI (Swagger) Documentation
 * This is the API documentation generated from the swagger comments in the backend. These correspond directly to the client library.
 *
 * The version of the OpenAPI document: N/A
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.HandoffOptionsToJSON = exports.HandoffOptionsFromJSONTyped = exports.HandoffOptionsFromJSON = exports.HandoffOptions = void 0;
/**
 *
 * @export
 * @enum {string}
 */
var HandoffOptions;
(function (HandoffOptions) {
    HandoffOptions["DELIVERY"] = "DELIVERY";
    HandoffOptions["PICKUP"] = "PICKUP";
    HandoffOptions["DIGITAL"] = "DIGITAL";
})(HandoffOptions = exports.HandoffOptions || (exports.HandoffOptions = {}));
function HandoffOptionsFromJSON(json) {
    return HandoffOptionsFromJSONTyped(json, false);
}
exports.HandoffOptionsFromJSON = HandoffOptionsFromJSON;
function HandoffOptionsFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
exports.HandoffOptionsFromJSONTyped = HandoffOptionsFromJSONTyped;
function HandoffOptionsToJSON(value) {
    return value;
}
exports.HandoffOptionsToJSON = HandoffOptionsToJSON;
